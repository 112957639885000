import { AnimationOnScroll } from "react-animation-on-scroll"
import React from "react"
import { Container, Table } from "react-bootstrap"
import { Link } from "gatsby"

export default function Participate() {
  return (
    <>
      <div className="mt-5 pt-5 mb-5">
        <h1 className="heading mb-5">JOIN AS A PARTICIPANT</h1>
        <Table
          responsive
          striped
          bordered
          style={{ fontSize: "23px", textAlign: "center" }}
        >
          <thead>
            <tr>
              <th className="background-price-td-first"></th>
              <th className="background-price-td-first" colSpan={2}>
                Day Delegates
              </th>
              <th className="background-price-td-first" colSpan={2}>
                Residential Delegates
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="background-price-td-first"></td>
              <td className="background-price-td">Regional</td>
              <td className="background-price-td">National</td>
              <td className="background-price-td">Regional</td>
              <td className="background-price-td">National</td>
            </tr>
            <tr>
              <td className="background-price-td">INR*</td>
              <td>
                5,000
                <br />
                (18% GST Extra)
              </td>
              <td>
                7,500
                <br />
                (18% GST Extra)
              </td>
              <td>
                20,000
                <br />
                (18% GST Extra)
              </td>
              <td>
                25,000
                <br />
                (18% GST Extra)
              </td>
            </tr>
            <tr>
              <td className="background-price-td">Details</td>
              <td>
                Non-Residential Delegates Day pass (with Lunch & Tea): Includes
                access to the conclave. <br />
                <br />
                In addition, you will get access to all thematic sessions and
                workshops.
              </td>
              <td>
                Non-Residential Delegates Day pass (with Lunch & Tea): Includes
                access to the conclave. <br />
                <br />
                In addition, you will get access to all thematic sessions and
                workshops.
              </td>
              <td>
                Residential Delegates Pass (with Lunch + Dinner): It includes
                Conclave access. <br />
                <br /> Stay at the designative location for one night, including
                and breakfast. You will be able to access all of the thematic
                sessions and workshops.
              </td>
              <td>
                Residential Delegates Pass (with Lunch + Dinner): It includes
                Conclave access. <br />
                <br />
                Stay at the designative location for one night, including one
                networking dinner and breakfast. You will be able to access all
                of the thematic sessions and workshops.
              </td>
            </tr>
          </tbody>
        </Table>
        {/* <Link
          to="/register"
          className="btn learn-more-btn my-4"
          style={{ marginRight: "5px" }}
        >
          REGISTER NOW
        </Link> */}
      </div>
    </>
  )
}
