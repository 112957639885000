import React from "react"
import { FaCheck } from "react-icons/fa"
const check = <FaCheck />

/*
 *
 *
 * UTILITIES
 *
 *
 */
export const keyLabelMap = {
  // Organised by depth
  0: {
    inr: "INR* (18% GST Extra)",
    logoInConclaveBrochure: "Logo in Conclave Brochure",
    backDropSideBackDrop: "Backdrop/Side backdrop",
    websiteLogoPlacement: "Website logo placement",
    speakingOpportunity: "Speaking Opportunity",
    socialMediaPromotion: "Social Media Promotion",
    // exhibitionStall: "Exhibition Stall",
    displayPromotion: "Display Promotion (6ft x 3ft)",
    premiumDelegatePass: "Premium Delegate Pass (Lunch + Networking Dinner)",
    nameLogoOnLanyard: "Name/logo on Lanyard",
    logoOnAgenda: "Logo on the Agenda",
    agendaLunchWithVVIPs:
      "Agenda Lunch with VVIPs/CSR Heads/ Government Officials (for 2 people/organization)",
  },
  1: {
    category1: (
      <>
        Category 1<br /> (Strategic Partner, Corporate Partner, State Host)
      </>
    ),
    category2: (
      <>
        Category 2<br /> (Sectoral Corporate Partner, Sectoral Skilling Partner)
      </>
    ),
    category3: (
      <>
        Category 3<br /> (NGO Partner, MSME Partner)
      </>
    ),
  },
}

const record = (category1, category2, category3) => ({
  category1,
  category2,
  category3,
})

/*
 *
 *
 * MAIN DATA
 *
 *
 */

export const data = {
  inr: record(
    "Will be Event Specific",
    "Will be Event Specific",
    "Will be Event Specific"
  ),
  logoInConclaveBrochure: record(check, check, check),
  backDropSideBackDrop: record(
    <>{check} Ultra Premium</>,
    <>{check} Premium</>,
    check
  ),
  websiteLogoPlacement: record(
    <>{check} Ultra Premium</>,
    <>{check} Premium</>,
    check
  ),
  speakingOpportunity: record("Inaugural Keynote + Panel", "Panel", "Panel"),
  socialMediaPromotion: record(
    <>{check} Ultra Premium</>,
    <>{check} Premium</>,
    check
  ),
  // exhibitionStall: record(
  //   "Ultra Premium Placement",
  //   "Premium Placement",
  //   check
  // ),
  displayPromotion: record("3 standees", "2 standees", "1 standee"),
  premiumDelegatePass: record(7, 5, 2),
  nameLogoOnLanyard: record(check, check),
  logoOnAgenda: record(check, check),
  agendaLunchWithVVIPs: record(check, check, check),
}
