import React, { useMemo } from "react"
import Table from "react-bootstrap/Table"

export default function JSONTableViewer(props) {
  const { data, keyLabelMap, heading, styling = {} } = props

  const columns = useMemo(
    () =>
      Object.values(data).reduce((columns, row) => {
        Object.keys(row).forEach(column => {
          if (columns.indexOf(column) < 0) columns.push(column)
        })

        return columns
      }, []),
    [data]
  )

  return (
    <Table
      responsive
      striped
      bordered
      style={{ fontSize: "23px", textAlign: "center" }}
      className="mb-4"
    >
      <thead>
        {heading && (
          <tr>
            <th
              className="heading background-price-td-first"
              colSpan={columns.length + 1}
            >
              {heading}
            </th>
          </tr>
        )}
        <tr>
          <th className="background-price-td"></th>
          {columns.map(columnName => (
            <th className="background-price-td" key={columnName}>
              {keyLabelMap[1][columnName]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map(rowName => (
          <tr key={rowName}>
            <td
              className="background-price-td"
              style={{ width: "200px", ...(styling.rowLabel || {}) }}
            >
              {keyLabelMap[0][rowName]}
            </td>
            {Object.values(data[rowName]).map((value, index) => (
              <td key={index}>{value || "-"}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
