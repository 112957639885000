import React from "react"
import { Link } from "gatsby"
import JSONTableViewer from "../components/JSONTableViewer"
import { data, keyLabelMap } from "../data/sponsors/sponsorship-data"
import join from "../images/newDesign/join.jpg"
import Participate from "./participate"

export default function sponsor() {
  return (
    <>
      <div className="mt-5 pt-5 mb-5 px-5 container-custom">
        <Link
          to="/user-interest"
          className="btn learn-more-btn my-4"
          style={{ marginRight: "5px" }}
        >
          CLICK TO JOIN AS A SPEAKER, SPONSOR, OR PARTICIPANT
        </Link>
        <h1 className="heading my-5">Join as a Sponsor</h1>
        <div className="row">
          <div className="col-md-7">
            <div align="justify" className="comman-para">
              Partnering with the Indian ESG Network and Awards will help you
              network, promote and brand your business services with senior
              professionals, ESG experts from various sectors and industries.
              <br />
              <br />
              Senior professionals interested in upgrading their knowledge about
              the newest trends, challenges, and developing methods that can
              help your ESG process reach the next level are among those who
              will attend the conclaves.
              <br />
              <br />
              We have a collection of common branding options that may be
              tailored to your individual requirements. Take a few moments to go
              over the Sponsorship and Exhibitor Plans below to maximize your
              involvement.
            </div>
          </div>
          <div className="col-md-5">
            <img src={join} className="img-fluid" alt={``} />
          </div>
        </div>
        <div className="mt-5 pt-5">
          <section>
            <div>
              <JSONTableViewer
                data={data}
                styling={{
                  rowLabel: {
                    width: "300px",
                  },
                }}
                keyLabelMap={keyLabelMap}
                heading="Sponsorship/ Partnership and Co-Hosting Opportunities"
              />
              <div style={{ fontSize: "18px" }}>
                <h4 className="pt-5 fw-bold">Privacy Policy</h4>
                <div className="card border-0">
                  <div className="card-body">
                    <ul className="comman-para">
                      <li style={{ wordBreak: "break-all" }}>
                        By registering yourself as a
                        participant/delegate/sponsor/speaker with Indian ESG
                        Network it will be deemed that you have read, understood
                        and agreed to fully abide by all the terms and
                        conditions of the network as contained herein. There is
                        no refund post registration conformation.
                      </li>
                      <li>
                        As a general rule, we do not collect/store Personal
                        Information about you. Your email address and other
                        details will only be recorded if you choose to register
                        yourself for the event/s. Your email address will not be
                        used for any other purpose, and will not be disclosed,
                        without your consent.
                      </li>
                      <li>
                        These terms and conditions shall be governed by and
                        construed in accordance with the Indian Laws. Any
                        dispute arising under these terms and conditions shall
                        be subject to the jurisdiction of the courts of New
                        Delhi, India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <Link
            to="/sponsor-form"
            className="btn learn-more-btn my-4 "
            style={{ marginRight: "5px" }}
          >
            Register as Sponsor
          </Link> */}
        </div>
        <Participate />
        <div className="mt-5 pt-5 mb-5">
          <h1 className="heading mb-5">Join as a Speaker</h1>
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
              style={{
                backgroundColor: "#f7f4e7",
              }}
            >
              <div class="card border-0 p-2 bg-transparent">
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">Environmental</h4>
                  <ul className="comman-para">
                    <li>Sustainability</li>
                    <li>Climate Change </li>
                    <li>How to make a sustainable business</li>
                    <li>Environment Disclosures</li>
                    <li>Challenges in sustainability</li>
                    <li>Any other (pls specify)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border common-card">
              <div class="card border-0 p-2 bg-transparent">
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">Social</h4>
                  <ul className="comman-para">
                    <li>Impact of ESG on CSR Strategy</li>
                    <li>Linking Social Projects to ESG Roadmap</li>
                    <li>Social Impact Assessment</li>
                    <li>Social Audits</li>
                    <li>Social Stock Exchange</li>
                    <li>Any other (pls specify)</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border common-card">
              <div class="card border-0 p-2 bg-transparent">
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">Governance</h4>
                  <ul className="comman-para">
                    <li>Risk Management for sustainable business</li>
                    <li>Key Governance aspects for sustainability</li>
                    <li>Role of BOD in ESG Roadmap</li>
                    <li>Policies for a sustainable business</li>
                    <li>Diversity & Inclusion</li>
                    <li>Any other (pls specify)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border common-card">
              <div class="card border-0 p-2 bg-transparent">
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">
                    Regulatory & Finance Regulatory
                  </h4>
                  <ul className="comman-para">
                    <li>Regulatory Frameworks for ESG</li>
                    <li>BRSR Framework by SEBI </li>
                    <li>Challenges in sustainability reporting</li>
                    <li>BRSR reporting & assurance (with best practises)</li>
                    <li>
                      International reporting standards (GRI, TCFD, others)
                    </li>
                    <li>Any other (pls specify)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <Link
            to="/speaker-form"
            className="btn learn-more-btn my-4 "
            style={{ marginRight: "5px" }}
          >
            Register as Speaker
          </Link> */}
        </div>
      </div>
    </>
  )
}
